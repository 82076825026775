.join{
    display: flex;
    gap: 10rem;
    padding: 0 3rem;
}

.left-j{
    color: azure;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
}

.left-j>hr{
    width: 20rem;
    position: absolute;
    border: 2px solid orange;
    border-radius: 50%;
    position: absolute;
    margin-top: -2rem;
}

.right-j{
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.email-container{
    display: flex;
    gap: 3rem;
    background-color: grey;
    padding: 1rem 2rem;
    border-radius: 1rem;
}

.email-container>input{
    background-color: transparent;
    border: none;
    outline: none;
    color: rgb(70, 70, 70);
}

::placeholder{
    color: rgb(221, 221, 221);
    font-family: Arial, Helvetica, sans-serif;
}

.btn-j{
    background-color: orangered;
    color: azure;
    border-radius: 1rem;
}

@media screen and (max-width: 988px){

    html,body{
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
      }
    
    .join{
        flex-direction: column;
        position: absolute;
        margin-top: 170rem;
        gap: 4rem;
        padding: 2rem;
    }
}