.Programs{
    color: azure;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0rem;
    margin-top: 5rem;
}

.programs-header{
    text-align: center;
    justify-content: center;
    text-transform: uppercase;
    gap: 5rem;
    display: flex;
    font-size: 3rem;
    font-style: italic;
    font-weight: 200;
}

.programs-categories{
    display: flex;
    gap: 2rem;
    margin-left: 3rem;
    margin-right: 3rem;
    margin-bottom: 3rem;
}

.categories{
    display: flex;
    flex-direction: column;
    background-color: grey;
    padding: 2rem;
    gap: 1rem;
    color: azure;
    justify-content: space-between;
}

.categories>:nth-child(1){
    height: 2rem;
    width: 2rem;
    fill: rgb(216, 184, 255);
}

.categories>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
}

.categories>:nth-child(3){
    font-size: 0.9rem;
    line-height: 1rem;    
}

.join-now{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.join-now>img{
    width: 2rem;    
}

.categories:hover{
    background: var(--planCard) ;
    background-color: brown;
    cursor: pointer;
}

.programs-blur-2{
    height: 23rem;
    width: 23rem;
    left: 0rem;
    background: rgb(255, 0, 0);
}

.programs-blur-1{
    height: 23rem;
    width: 23rem;
    right: 0rem;
    background: rgb(255, 0, 0);
}



@media screen and (max-width: 988px){

    html,body{
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
      }
    
    .Programs{
        flex-direction: column;
        align-content: center;
        align-items: center;
        align-self: center;
        position: absolute;
        margin-top: 77rem;
    }
    .programs-header{
        display: flex;
        flex-direction: column;
    }

    .programs-categories{
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
        align-self: center;
    }
}